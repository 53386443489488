export const english = {
  pl: 'portfolio',
  homeAbout: {
    tit: 'Hi! 👋',
    desc: `I'm José Hidalgo Frontend developer focused on building beautiful interfaces and experiences`,
    butt: 'MORE ABOUT ME'
  },
  homeblog: {
    title: 'My Blog',
    desc: 'Resources I used.'
  },
  homePort: {
    desc: 'Some of my latest projects',
    butt: 'See all projects',
  },
  menu: {
    footer: 'Developed by José Hidalgo',
    text: 'Want to work with me?',
    menuList: {
      ho: 'Home',
      bl: 'Blog',
      ab: 'About me',
      po: 'Portfolio',
      co: 'Contact',
    },
    contDesc: {
      desc: 'If you want to communicate with me or are interested in starting a project together, get in touch.',
      em: 'Send me and email.',
      so: 'Or send me a direct message on my social media.',
    }
  },
  about: {
    h1: 'Hi! I am',
    h2: 'José Hidalgo',
    p1: 'Fronted Developer',
    abh1: 'About me',
    abp1: 'Hi! I am José Hidalgo. I have a great passion for design and technology. I specialize in Frontend development although I also have a background in Backend and my passion is building elegant and professional websites and user interfaces.',
    abp2: 'My first steps in development and programming were in college where I learned the basics of C ++, after that I discovered web development, which gave me and hooked me, so I decided to focus on it and forge a career in frontend developer. It was for this reason that I made the decision to learn everything related to web development in a self-taught way and thus be able to build professional and interactive websites.',
    abp3: 'Although my goal is to become a full Frontend developer, I will not be left alone with it. My goal is to take it to the next level and become a Full Stack developer. It was for this reason that I decided to join Henry where I learned technologies and work methodologies that would allow me to meet my goals.',
    abp4: 'I prepare myself daily to meet the challenges in this ever-changing world and will do my best to meet my goals.',
    skh1: 'My Skills',
    skp1: 'For development / coding, I use different tools specific to application or website development. The tools and technologies that I use and are familiar with are listed below.',
    skh41: 'Software / tools',
    skh42: 'Web development',
    mhh3: '“Choose a job you love and you will never have to work a day in your life.”',
    bt: 'See my work'
  },
  blog: {
    title: 'My Posts',
    desc: 'Welcome to my blog posts page!',
    read: 'Read post ------>'
  },
  portfolio: {
    t1: 'My projects',
    t2: 'Note',
    ap1: 'My portfolio consists of designs for websites, applications, as well as some extra tools. In each job I put the maximum effort, perseverance, attention and creativity.',
    ap2: 'These are some of the projects that I develop. You can also find the code of each project on my',
    cards: [
      {
      title: 'Redesigned Pixabay Clone',
        img: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1618085445/pixa_otzsa1.jpg',
        imgMobile: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1618085450/pixa_1_ko3zwy.jpg',
        descTitle: 'Project descriptión.',
        desc: "A basic Pixabay clone following a design by behance. For its construction, React, Redux, the Pixabay API, Styled Components were used for the styles and Firebase / Firestore for the authentication and the database.",
        liTitle1: 'Platform',
        liDesc1: 'Web/Mobile',
        liTitle2: 'Category',
        liDesc2: 'Productivity',
        liTitle3: 'Developer',
        liDesc3: 'José Hidalgo',
        techT: 'Technologies Used',
        techP: "HTML<span>/</span>CSS<span>/</span>JavaScript<span>/</span>React<span>/</span>Redux<span>/</span>Firebase<span>/</span>Styled-Components",
        butDemo: 'Launch App',
        butRepo: 'Source Code',
        linkDemo: 'https://pixabay2.netlify.app/',
        linkRepo: 'https://github.com/ImJoseHidalgo/pixabay2',
      },
      {
        title: 'Custom URL shortener',
        img: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1618618369/127.0.0.1_5500_index.html_1_mwxhki.png',
        imgMobile: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1618618374/127.0.0.1_5500_index.html_1_gm9trw.jpg',
        descTitle: 'Project descriptión.',
        desc: "A custom URL shortener with Cloudflare Workers. For its construction, HTML, CSS, JavaScript and Cloudflare Workers for the database and redirects.",
        liTitle1: 'Platform',
        liDesc1: 'Web/Mobile',
        liTitle2: 'Category',
        liDesc2: 'Utility',
        liTitle3: 'Developer',
        liDesc3: 'José Hidalgo',
        techT: 'Technologies Used',
        techP: "HTML<span>/</span>CSS<span>/</span>JavaScript<span>/</span>Cloudflare",
        butDemo: 'Launch App',
        butRepo: 'Source Code',
        linkDemo: 'https://josee.link/',
        linkRepo: 'https://github.com/ImJoseHidalgo/url-shortener/',
      },
      {
        title: 'Personal Portfolio',
        img: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1619304889/localhost_3000__1_1_tyvdni.png',
        imgMobile: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1619304892/mibilecss_1_sackku.png',
        descTitle: 'Project descriptión.',
        desc: "Originally my portfolio was created with HTML, CSS and JavaScript Vanilla. This is the second version of my portfolio built in React and Using Sanity.io to store the blog content.",
        liTitle1: 'Platform',
        liDesc1: 'Web/Mobile',
        liTitle2: 'Category',
        liDesc2: 'Productivity',
        liTitle3: 'Developer',
        liDesc3: 'José Hidalgo',
        techT: 'Technologies Used',
        techP: "HTML<span>/</span>CSS<span>/</span>JavaScript<span>/</span>React<span>/</span>Redux<span>/</span>Sanity<span>/</span>I.Observer<span>/</span>Styled-Components",
        butDemo: 'Launch App',
        butRepo: 'Source Code',
        linkDemo: 'https://www.imjosehidalgo.com/',
        linkRepo: 'https://github.com/ImJoseHidalgo/portfolio-react',
      },
      {
        title: 'React Gift Mobile Web App',
        img: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1618084276/gif_clr760.png',
        imgMobile: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1618084212/gif_1_1_uopifm.png',
        descTitle: 'Project descriptión.',
        desc: "This is a mobile web application that uses the Giphy API to search, display, save, and share gifs. It was built with React, Wouter, Web-Share API, LocalStorage.",
        liTitle1: 'Platform',
        liDesc1: 'Web/Mobil',
        liTitle2: 'Category',
        liDesc2: 'Productivity',
        liTitle3: 'Developer',
        liDesc3: 'José Hidalgo',
        techT: 'Technologies Used',
        techP: "HTML<span>/</span>CSS<span>/</span>JavaScript<span>/</span>React",
        butDemo: 'Launch App',
        butRepo: 'Source Code',
        linkDemo: 'https://gif9.netlify.app/',
        linkRepo: 'https://github.com/ImJoseHidalgo/giffApp',
      },
      {
        title: 'Social Media Dashboard',
        img: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1618082409/dashboard_yrfnsq.jpg',
        imgMobile: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1618082417/dashboard_1_fqyqio.jpg',
        descTitle: 'Project descriptión.',
        desc: "This is a layout challenge for the Frontend Mentor site, focusing on achieving a dark mode that will capture the theme of the device / computer and according to it, it will change the theme of the site automatically.",
        liTitle1: 'Platform',
        liDesc1: 'Web/Mobil',
        liTitle2: 'Category',
        liDesc2: 'Productivity',
        liTitle3: 'Developer',
        liDesc3: 'José Hidalgo',
        techT: 'Technologies Used',
        techP: "HTML<span>/</span>CSS<span>/</span>JavaScript",
        butDemo: 'Launch App',
        butRepo: 'Source Code',
        linkDemo: 'https://imjosehidalgo.github.io/Dark-Mode-Practice/',
        linkRepo: 'https://github.com/ImJoseHidalgo/Dark-Mode-Practice',
      },
      {
        title: 'Chat app CSS illustration',
        img: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1618084683/des1.vercel.app__2_1_h1onm4.png',
        imgMobile: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1618084717/des1.vercel.app__2_1_uxislf.jpg',
        descTitle: 'Project descriptión.',
        desc: "This is a layout challenge for the Frontend Mentor site with a focus on achieving an exact copy of the given design, with its respective animation.",
        liTitle1: 'Platform',
        liDesc1: 'Web/Mobile',
        liTitle2: 'Category',
        liDesc2: 'Productivity',
        liTitle3: 'Developer',
        liDesc3: 'José Hidalgo',
        techT: 'Technologies Used',
        techP: "HTML<span>/</span>CSS<span>/</span>JavaScript",
        butDemo: 'Launch App',
        butRepo: 'Source Code',
        linkDemo: 'https://des1.vercel.app/',
        linkRepo: 'https://github.com/ImJoseHidalgo/des1',
      },
      {
        title: 'Dark Night Theme',
        img: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1618086316/theme_1_y00mra.png',
        imgMobile: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1618086352/theme_1_1_1_iwlnhz.png',
        descTitle: 'Project descriptión.',
        desc: "This is a custom theme that one fine day I thought of doing, once it was done I published it on the VS Code market place.",
        liTitle1: 'Platform',
        liDesc1: 'VS Code',
        liTitle2: 'Category',
        liDesc2: 'Utility',
        liTitle3: 'Developer',
        liDesc3: 'José Hidalgo',
        techT: 'Technologies Used',
        techP: "CSS<span>/</span>JavaScript",
        butDemo: 'Market Place',
        butRepo: 'Source Code',
        linkDemo: 'https://marketplace.visualstudio.com/items?itemName=josehidalgo.dark-night',
        linkRepo: 'https://github.com/ImJoseHidalgo/vscode-dark-night',
      },
    ],
  },
  contact: {
    title: 'Get in touch.',
    desc: "en",
    fname: 'Your name',
    femail: 'Your email',
    fsubject: 'Subject',
    ftext: "YOUR MESSAGE",
    fbutton: 'Send Message',
  }
}
export const spanish = {
  pl: 'portafolio',
  homeAbout: {
    tit: 'Hola! 👋',
    desc: `Soy José Hidalgo, desarrollador frontend enfocado en construir bellas interfaces y experiencias de usuario.`,
    butt: 'MÁS DE MI',
  },
  homeblog: {
    title: 'Mi Blog',
    desc: 'Notas y apuntes de recursos que utilice.'
  },
  homePort: {
    desc: 'Algunos de mis últimos proyectos',
    butt: 'Ver todos',
  },
  menu: {
    footer: 'Desarrollado por José Hidalgo',
    text: '¿Quieres trabajar conmigo?',
    menuList: {
      ho: 'Inicio',
      bl: 'Blog',
      ab: 'Sobre mi',
      po: 'Portafolio',
      co: 'Contacto',
    },
    contDesc: {
      desc: 'Si deseas comunicarte conmigo o te interesa comenzar un proyecto juntos, pónte en contacto.',
      em: 'Envíame un correo.',
      so: 'O envíame un mensaje directo en mis redes sociales.',
    }
  },
  about: {
    h1: 'Hola! Yo soy',
    h2: 'José Hidalgo',
    p1: 'Fronted Developer',
    abh1: 'Sobre mi',
    abp1: '¡Hola! Soy José Hidalgo. Tengo una gran pasión por el diseño y la tecnología. Me especializo en desarrollo Frontend aunque támbien tengo conocimiento en Backend y mi pasión es construir sitios web e interfaces de usuario elegantes y profesionales.',
    abp2: 'Mis primeros pasos en el desarrollo y la programacón fueron en la universidad donde aprendi las baces en C++, luego de eso descubri el desarrollo web, que me dascinó y me enganchó, así que decidí enfocarme a ello y forjar una carrera en como desarrollador frontend. Fue por esto que tome la desición de aprender de forma autodidacta todo lo relacionado con desarrollo web y asi poder construir sitios web profesionales e interactivos.',
    abp3: 'Aunque mi objetivo es convertirme en un desarrollador Frontend completo, no me quedaré solo con ello. Mi objetivo es llevarlo al siguiente nivel y convertirme en un desarrollador Full Stack. Fue por ello que decidi ingresar a Henry donde aprendi tecnologias y metodologias de trabajo que me permitieran cumplir mis metas.',
    abp4: 'Me preparo a diario para afrontar los desafíos en este mundo en constante cambio y haré todo lo posible para cumplir mis metas.',
    skh1: 'Mis Skills',
    skp1: 'Para el desarrollo / codificación, utilizo diferentes herramientas específicas para el desarrollo de la aplicación o sitio web. A continuación se enumeran las herramientas y tecnologías que utilizo y con las que estoy familiarizado.',
    skh41: 'Software / herramientas',
    skh42: 'Desarrollo web',
    mhh3: '"Elije un trabajo que te guste y nunca tendrás que trabajar un día de tu vida".',
    bt: 'Ve mi trabajo'
  },
  blog: {
    title: 'Hola!!!',
    desc: 'Éste es mi blog!',
    read: 'Leer publicación ------>'
  },
  portfolio: {
    t1: 'Mis proyectos',
    t2: 'Nota',
    ap1: 'Mi portafolio consta de diseños para sitios web, aplicaciones, así como algunos tools extra. En cada trabajo pongo el máximo esfuerzo, constancia, atención y creatividad.',
    ap2: 'Estos son algunos de los proyectos que desarrolle. También podras encontrar el codigo de cada proyecto en mi',
    cards: [
      {
        title: 'Redesigned Pixabay Clone',
        img: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1618085445/pixa_otzsa1.jpg',
        imgMobile: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1618085450/pixa_1_ko3zwy.jpg',
        descTitle: 'Descripción del proyecto.',
        desc: "Un clon basico de Pixabay siguiendo un diseño de behance. Para su construcción se uso React, Redux, la API de Pixabay, Styled Components para los estilos y Firebase / Firestore para la autentucación y la base de datos.",
        liTitle1: 'Plataforma',
        liDesc1: 'Web',
        liTitle2: 'Categoria',
        liDesc2: 'Productividad',
        liTitle3: 'Desarrollador',
        liDesc3: 'José Hidalgo',
        techT: 'Tecnologías Utilizadas',
        techP: "HTML<span>/</span>CSS<span>/</span>JavaScript<span>/</span>React<span>/</span>Redux<span>/</span>Firebase<span>/</span>Styled-Components",
        butDemo: 'Ejecutar aplicación',
        butRepo: 'Código fuente',
        linkDemo: 'https://pixabay2.netlify.app/',
        linkRepo: 'https://github.com/ImJoseHidalgo/pixabay2',
      },
      {
        title: 'Acortador de URLs personalizado',
        img: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1618618369/127.0.0.1_5500_index.html_1_mwxhki.png',
        imgMobile: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1618618374/127.0.0.1_5500_index.html_1_gm9trw.jpg',
        descTitle: 'Descripción del proyecto.',
        desc: "Un acortador de URL personalizado con Cloudflare Workers. Para su construcción se utilizo, HTML, CSS, JavaScript y Cloudflare Workers para la base de datos y redireccionamientos.",
        liTitle1: 'Plataforma',
        liDesc1: 'Web/Mobile',
        liTitle2: 'Categoria',
        liDesc2: 'Utilidad',
        liTitle3: 'Desarrollador',
        liDesc3: 'José Hidalgo',
        techT: 'Tecnologías Utilizadas',
        techP: "HTML<span>/</span>CSS<span>/</span>JavaScript<span>/</span>Cloudflare",
        butDemo: 'Ejecutar aplicación',
        butRepo: 'Código fuente',
        linkDemo: 'https://josee.link/',
        linkRepo: 'https://github.com/ImJoseHidalgo/url-shortener',
      },
      {
        title: 'Portafolio Personal',
        img: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1619304889/localhost_3000__1_1_tyvdni.png',
        imgMobile: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1619304892/mibilecss_1_sackku.png',
        descTitle: 'Descripción del proyecto.',
        desc: "Originalmente mi portafolio fue creado con HTML, CSS y JavaScript Vanilla. Esta es la segunda versión de mi portafolio construida en React y Usando Sanity.io para almacenar el contenido del Blog.",
        liTitle1: 'Plataforma',
        liDesc1: 'Web/Mobile',
        liTitle2: 'Categoria',
        liDesc2: 'Productividad',
        liTitle3: 'Desarrollador',
        liDesc3: 'José Hidalgo',
        techT: 'Tecnologías Utilizadas',
        techP: "HTML<span>/</span>CSS<span>/</span>JavaScript<span>/</span>React<span>/</span>Redux<span>/</span>Sanity<span>/</span>I.Observer<span>/</span>Styled-Components",
        butDemo: 'Ejecutar aplicación',
        butRepo: 'Código fuente',
        linkDemo: 'https://www.imjosehidalgo.com/',
        linkRepo: 'https://github.com/ImJoseHidalgo/portfolio-react',
      },
      {
        title: 'React Gift Mobile Web App',
        img: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1618084276/gif_clr760.png',
        imgMobile: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1618084212/gif_1_1_uopifm.png',
        descTitle: 'Descripción del proyecto.',
        desc: "Esta es una aplicación web pensada en mobile que usa la API de Giphy para buscar, mostrar guardar y compartir gifs. Fue construido con React, Wouter, Web-Share API, LocalStorage.",
        liTitle1: 'Plataforma',
        liDesc1: 'Web/Mobil',
        liTitle2: 'Categoria',
        liDesc2: 'Productividad',
        liTitle3: 'Desarrollador',
        liDesc3: 'José Hidalgo',
        techT: 'Tecnologías Utilizadas',
        techP: "HTML<span>/</span>CSS<span>/</span>JavaScript<span>/</span>React",
        butDemo: 'Ejecutar aplicación',
        butRepo: 'Código fuente',
        linkDemo: 'https://gif9.netlify.app/',
        linkRepo: 'https://github.com/ImJoseHidalgo/giffApp',
      },
      {
        title: 'Social Media Dashboard',
        img: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1618082409/dashboard_yrfnsq.jpg',
        imgMobile: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1618082417/dashboard_1_fqyqio.jpg',
        descTitle: 'Descripción del proyecto.',
        desc: "Este es un reto de maquetado del sitio Frontend Mentor haciendo foco en lograr un dark mode que captara el tema del dispositivo / computador y según el, cambiara el tema del sitio automaticamente.",
        liTitle1: 'Plataforma',
        liDesc1: 'Web/Mobil',
        liTitle2: 'Categoria',
        liDesc2: 'Productividad',
        liTitle3: 'Desarrollador',
        liDesc3: 'José Hidalgo',
        techT: 'Tecnologías Utilizadas',
        techP: "HTML<span>/</span>CSS<span>/</span>JavaScript",
        butDemo: 'Ejecutar aplicación',
        butRepo: 'Código fuente',
        linkDemo: 'https://imjosehidalgo.github.io/Dark-Mode-Practice/',
        linkRepo: 'https://github.com/ImJoseHidalgo/Dark-Mode-Practice',
      },
      {
        title: 'Chat app CSS illustration',
        img: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1618084683/des1.vercel.app__2_1_h1onm4.png',
        imgMobile: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1618084717/des1.vercel.app__2_1_uxislf.jpg',
        descTitle: 'Descripción del proyecto.',
        desc: "Este es un reto de maquetado del sitio Frontend Mentor con foco en lograr una copia exacta del diseño dado, con su respectiva animación.",
        liTitle1: 'Plataforma',
        liDesc1: 'Web/Mobile',
        liTitle2: 'Categoria',
        liDesc2: 'Productividad',
        liTitle3: 'Desarrollador',
        liDesc3: 'José Hidalgo',
        techT: 'Tecnologías Utilizadas',
        techP: "HTML<span>/</span>CSS<span>/</span>JavaScript",
        butDemo: 'Ejecutar aplicación',
        butRepo: 'Código fuente',
        linkDemo: 'https://des1.vercel.app/',
        linkRepo: 'https://github.com/ImJoseHidalgo/des1',
      },
      {
        title: 'Dark Night Theme',
        img: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1618086316/theme_1_y00mra.png',
        imgMobile: 'https://res.cloudinary.com/dwqr4s7gp/image/upload/v1618086352/theme_1_1_1_iwlnhz.png',
        descTitle: 'Descripción del proyecto.',
        desc: "Este es un tema personalizado que un buen día se me ocurrio hacer, una vez realizado lo publique en market place de VS Code.",
        liTitle1: 'Plataforma',
        liDesc1: 'VS Code',
        liTitle2: 'Categoria',
        liDesc2: 'Utilidad',
        liTitle3: 'Desarrollador',
        liDesc3: 'José Hidalgo',
        techT: 'Tecnologías Utilizadas',
        techP: "CSS<span>/</span>JavaScript",
        butDemo: 'Market Place',
        butRepo: 'Código fuente',
        linkDemo: 'https://marketplace.visualstudio.com/items?itemName=josehidalgo.dark-night',
        linkRepo: 'https://github.com/ImJoseHidalgo/vscode-dark-night',
      },
    ],
  },
  contact: {
    title: 'Ponte en contacto.',
    desc: "es",
    fname: 'Tu nombre',
    femail: 'Tu email',
    fsubject: 'Asunto',
    ftext: "TU MENSAJE",
    fbutton: 'Enviar mensaje',
  }
}