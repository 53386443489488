import styled from "styled-components";

export const NotFound = styled.section`
  h1 {
    background: #c4c4c4;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`